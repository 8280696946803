export const reviewsData = [
  {
    id: "0",
    name: "Farwa",
    city: "Sydney",
    country: "Australia",
    review: `Miss Maheen is doing a wonderful job so far and we are very delighted with the entire experience.
    Rubab has been learning a lot from her. Her pen gripping has improved so much since she started taking classes with Miss Maheen.
    Overall it's been great so far and I'd give her a 10/10 Dot & Line is a great platform and I'm so glad I discovered it online. I'm sitting in Australia and yet my daughter is getting coached by Pakistani teachers.
    The dashboard itself is so easy to use and also there are so many courses to choose from.
    I think I'll be a regular here!`,
  },
  {
    id: "1",
    name: "Sama",
    city: "Sydney",
    country: "Australia",
    review:
      "Miss Sameen is doing great with Mina in regards to learning. Mina is enjoying her lesson with her.",
  },
  {
    id: "2",
    name: "Yasira Mazhar",
    city: "Mittagong",
    country: "Australia",
    review:
      "Joining dot and line proved to be a great decision till now. Arham is really enjoying his classes, learning a lot, and he actually looks forward to his class. Ms. Ayesha is a wonderful teacher, and I really admire her teaching style. She pays attention to small details and conducts the class very smoothly, which keeps Arham hooked and interested. Plus, she is extremely cooperative. Overall, it's been a satisfying experience for me.",
  },
  {
    id: "3",
    name: "Houria Jan",
    city: "Sydney",
    country: "Australia",
    review:
      "I am very happy with Rabeia’s teaching style. She is very interactive with Terina and Terina appreciates it as well. Also, Rabeia is very understanding in scheduling/rescheduling classes as per Terina’s availability.",
  },
  {
    id: "4",
    name: "Nigah",
    city: "Melbourne",
    country: "Australia",
    review:
      "I am satisfied with the teachers progress and I look forward to continuing working with her to improve my daughter's Urdu language skills.",
  },
  {
    id: "5",
    name: "Uswa",
    city: "Perth",
    country: "Australia",
    review:
      "I'm completely satisfied with Maliha's class.She was very cooperative and hard-working teacher.She did very well with Wali.I admire her for her way of teaching.This is the reason that I have continued the classes with her in the school days.",
  },
  {
    id: "6",
    name: "Sidra Iman",
    city: "Carlingford",
    country: "Australia",
    review:
      "My kids are doing well with Miss Sameera Tariq. She is very focused and knows how to deal with the young children. So, I am happy so far.",
  },
];

export const grades = [
  {
    id: "1",
    label: "Nursery",
    value: "Nursery",
  },
  {
    id: "2",
    label: "KG",
    value: "KG",
  },
  {
    id: "3",
    label: "Grade 1",
    value: "Grade 1",
  },
  {
    id: "4",
    label: "Grade 2",
    value: "Grade 2",
  },
  {
    id: "5",
    label: "Grade 3",
    value: "Grade 3",
  },
  {
    id: "6",
    label: "Grade 4",
    value: "Grade 4",
  },
  {
    id: "7",
    label: "Grade 5",
    value: "Grade 5",
  },
  {
    id: "8",
    label: "Grade 6",
    value: "Grade 6",
  },
  {
    id: "9",
    label: "Grade 7",
    value: "Grade 7",
  },
  {
    id: "10",
    label: "Grade 8",
    value: "Grade 8",
  },
  {
    id: "11",
    label: "Grade 9",
    value: "Grade 9",
  },
];

export const naplanGrades = [
  {
    id: "0",
    label: "Grade 3",
    value: "Grade 3",
  },
  {
    id: "1",
    label: "Grade 5",
    value: "Grade 5",
  },
  {
    id: "2",
    label: "Grade 7",
    value: "Grade 7",
  },
  {
    id: "3",
    label: "Grade 9",
    value: "Grade 9",
  },
];
